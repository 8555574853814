<template>
	<!-- 会议记录 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理会议</div>
			<div class="line"></div>
			<el-row
				style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 50px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
			</el-row>
			<div class="line" style="margin-top: 10px;"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">新增</el-button>
					<el-button v-preventReClick :disabled="disabledBatchDelete" class="btn" type="primary" size="small"
						@click="deleteBatch()">删除</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<!-- <el-form-item label="类型">
							<el-select class="selItemInput" v-model="meetKind" placeholder="请选择">
								<el-option v-for="item in meetingTypeList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label="会议时间" class="selFormItem" style="margin-top:2px">
							<el-date-picker class="selDateInput" v-model="meetTime" value-format="yyyy-MM-dd"
								type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 490px;" body-style="height:450px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="430" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%;margin-bottom: 20px;" header-cell-class-name="stepPointTHeader"
								:cell-style="{ padding: '0px' }" @selection-change="handleSelectionChange">
								<el-table-column type="selection" width="60" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="meetNumber" label="会议编号" width="200" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="viewPdf(scope.row.filePath, scope.row.fileUri, scope.row.fileName)">{{
												scope.row.meetNumber }}</el-button>
									</template>
								</el-table-column>
								<el-table-column prop="showTime" label="时间" width="200" align="left" header-align="center">
								</el-table-column>
								<el-table-column prop="meetTitle" label="会议主题" align="left" header-align="center"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="meetPlace" label="地点" width="320" align="center"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="meetRecorder" label="记录人" width="120" align="center">
								</el-table-column>
								<el-table-column label="操作" width="150" align="center"
									v-if="showLook || showEdit || showDelete">
									<template slot-scope="scope">
										<!-- <el-button v-preventReClick type="text" size="small"
									@click="meetingLookHandle(scope.row.meetId)" v-if="showLook">详情</el-button> -->
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.meetId)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="uploadMeetingHandle(scope.row)">上传</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="downloadFile(scope.row.fileUri, scope.row.fileName)" v-if="showLook">下载</el-button>
										<!-- <el-button v-preventReClick type="text" size="small"
									@click="deleteHandle(scope.row.meetId, scope.row.meetTitle)"
									v-if="showDelete">删除</el-button> -->
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>

		<el-dialog title="文件上传" :visible.sync="uploadMeetingVisible" width="40%" :close-on-click-modal="false">
			<el-form>
				<el-row>
					<el-col :span="6">
						<div class="item">
							文件
						</div>
					</el-col>
					<el-col :span="14">
						<el-upload v-if="!uploadFile.uploadFilePath" ref="uploadScanFile" class="upload-demo" action="action"
						drag :http-request="uploadScanFile" :show-file-list=false :auto-upload="true" style="margin-left: 10px;">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
						<div v-if="uploadFile.uploadFilePath" style="margin-left: 10px;">
							<el-button v-preventReClick type="success" size="small" @click="downloadFile( 
							uploadFile.uploadFileUri, uploadFile.uploadFileName)">查看</el-button>
							<!-- <el-button v-preventReClick type="success" size="small" @click="selectMeetFile(uploadFile.uploadFileUri, uploadFile.uploadFileName)">查看</el-button> -->
							<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="uploadMeetingFile()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="uploadMeetingVisible = false">取 消</el-button>
			</span>
		</el-dialog>

		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<meeting-list-look v-if="lookVisible" ref="meetingLook" @refreshDataList="getDataList"></meeting-list-look>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>

<script>
import AddOrUpdate from './meetingList-add-or-update'
import $common from "@/utils/common.js"
import MeetingListLook from "./meetingList-look";
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			viewPdfVisible: false,
			showSearch: false,
			showAdd: false,
			showLook: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			form: {},
			addOrUpdateVisible: false,
			lookVisible: false,
			disabledBatchDelete: true,
			uploadMeetingVisible: false,
			workNo: $common.getItem("workNo"),
			subjectNo: $common.getItem("subjectNo"),
			major: "",
			meetingTypeList: [],
			meetKind: "",
			dataList: [],
			meetTime: [],
			tableSelVal: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			uploadMeetId: '',
			uploadFile : {
				uploadFilePath : '',
				uploadFileUri: '',
				uploadFileName: ''
			},
			subjectList: []
		};
	},
	components: {
		MeetingListLook,
		AddOrUpdate,
		ViewPdf
	},
	mounted() {
		this.showSearch = $common.isAuth('meeting:search');
		this.showAdd = $common.isAuth('meeting:add');
		this.showLook = $common.isAuth('meeting:look');
		this.showEdit = $common.isAuth('meeting:edit');
		this.showDelete = $common.isAuth('meeting:delete');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
		this.getMeetingType();
		this.getSubjectDropDown();
	},
	methods: {
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						this.getDataList();
					}
				}
			});
		},
		//自适应高度
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		getRowClass(row, rowIndex) {
			if (!row.row.subList || row.row.subList.length === 0) {
				return 'row-expand-cover'
			}
			return ''
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		selGetDataList() {
			this.pageInde = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/meetinfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						meetKind: this.meetKind,
						subjectNo: this.subjectNo,
						meetTimeBegin: this.meetTime[0] ? this.meetTime[0] : "",
						meetTimeEnd: this.meetTime[1] ? this.meetTime[1] : "",
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		getMeetingType() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMeetingType/",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.meetingTypeList = data.body;
				}
			});
		},
		//删除
		deleteHandle(id, testItem) {
			this.$confirm(`确定对【会议主题=${testItem}】进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/meetinfo/delete",
					method: 'post',
					data: [id]
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.meetId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/meetinfo/delete",
					method: 'post',
					data: records
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			})
				.catch(() => { })
		},
		// 新增节点
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.subjectNo)
			})
		},
		meetingLookHandle(id) {
			this.lookVisible = true
			this.$nextTick(() => {
				this.$refs.meetingLook.init(id)
			})
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		resetFormData() {
			this.meetKind = "";
			this.meetTime = [];
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.meetId);
			if (checkIdList.includes(row.meetId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
		uploadMeetingHandle(row) {
			this.uploadMeetId = row.meetId
			this.uploadFile = {
				uploadFilePath : row.filePath,
				uploadFileUri : row.fileUri,
				uploadFileName: row.meetNumber
			}
			this.uploadMeetingVisible = true
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data;
				if (res.resultCode === 200) {
					this.uploadFile = {
						uploadFilePath : res.body.fileSavePath,
						uploadFileUri: res.body.fileUri,
					}
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
        selectMeetFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        delFile() {
            this.uploadFile = {
				uploadFilePath :  '',
				uploadFileUri : '',
			}
        },
		uploadMeetingFile() {
			if(this.uploadFile.uploadFilePath == undefined || this.uploadFile.uploadFilePath == '') {
				this.$message.error("请上传文件！")
				return
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/meetinfo/uploadMeetingFile",
				method: 'post',
				data: this.$http.adornData({
					meetId: this.uploadMeetId,
					filePath: this.uploadFile.uploadFilePath,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.uploadMeetingVisible = false;
							 this.uploadFile = {
								uploadFilePath :  '',
								uploadFileUri : '',
								uploadFileName : ''
							}
							this.getDataList()
						}
					})
				}
			})
		},
		setSubject(subject) {
			this.subjectNo = subject.displayValue
			this.getDataList()
		},
	}
};
</script>
<style lang="scss" scoped>
.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

::v-deep(.upload-demo .el-upload-dragger) {
	width: 200px;
	height: 80px;
}

::v-deep(.upload-demo .el-upload-dragger .el-icon-upload) {
	font-size: 30px;
	margin: 12px 1px;
	line-height: 20px;
}

::v-deep(.upload-demo .el-upload-dragger .el-upload__text) {
	line-height: 5px;
}

.item {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border: solid #dadada 1px;
	height: 35px !important;
	text-align: center;
	padding-top: 8px
}
</style>
